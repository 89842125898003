import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment as env } from "src/environments/environment";
import { ApiResponse, GeneratePresignedUrlResponseItem } from '../models/api';
import { BsaSubmissionRequest } from '../models/BsaSubmissionRequest';
import { TpoContact } from '../models/TpoContact';
import { UploadMetadata } from '../models/UploadMetadata';
import { AuthService } from './auth.service';
import { NmlsCompanyLicense } from '../models/NmlsCompanyLicense';
import { NmlsIndividualLicense } from '../models/NmlsIndividualLicense';


@Injectable({
  providedIn: 'root'
})
export class ApiService {


  private _baseUri: string;

  constructor(private _client: HttpClient, private _authService: AuthService) {
    this._baseUri = env.apiUri;
    this._authService.loginSubject.subscribe({
      next: (event: any) => {
        console.log("login subject emitted an event: ", event);
      }
    })
  }

  public getTpoContact() {
    const email = this._authService.getUserEmail();
    const result = email.pipe(
      switchMap((val: string) => {
        const url: string = `${this._baseUri}/api/tpocontact?email=${encodeURIComponent(val)}`;
        return this.get<ApiResponse<any>>(url)
      })
    );

    return result;
  }


  public isEmailAddressAllowedAccess(email: string): Observable<boolean> {
    const url: string = `${this._baseUri}/api/tpocontact?email=${encodeURIComponent(email)}`;
    const response$ = this.get<ApiResponse<TpoContact>>(url);
    const result$ = response$.pipe(
      map((response: ApiResponse<TpoContact>) => {
        return this.isTpoContactAuthorized(response.result);
      })
    );

    return result$;

  }


  /**
   * Retrieve metadata for a list of documents that have already been uploaded to s3.
   */
  public getMetadataForDocumentUploads(keys: string[]): Observable<ApiResponse<UploadMetadata[]>> {
    const url: string = `${this._baseUri}/api/external/uploads/metadata`;
    const body: any = { keys };
    const response = this.post<ApiResponse<UploadMetadata[]>>(url, body);
    return response;
  }


  /**
   * Generates a GUID to be used as the request submission id
   */
  public generateRequestSubmissionId(): Observable<ApiResponse<string>> {
    const url: string = `${this._baseUri}/api/external/submissions/generate`;
    const response = this.get<ApiResponse<string>>(url);
    return response;
  }


  /**
   * Cancel the document processing request submission
   */
  public cancelSubmission(requestSubmissionId: string, statusReasonCode: string): Observable<ApiResponse<any>> {
    const url: string = `${this._baseUri}/api/external/submissions/cancel`;
    const body: any = {
      RequestSubmissionId: requestSubmissionId,
      StatusReasonCode: statusReasonCode
    };
    return this.post<ApiResponse<any>>(url, body);
  }


  /**
   * Releases the document processing request submission
   */
  public releaseSubmission(requestSubmissionId: string): Observable<ApiResponse<any>> {
    const url: string = `${this._baseUri}/api/external/submissions/release`;
    const body: any = {
      RequestSubmissionId: requestSubmissionId
    };
    return this.post<ApiResponse<any>>(url, body);
  }

  /**
   * Submits the BSA request
   */
  public submit(request: BsaSubmissionRequest) {
    const url: string = `${this._baseUri}/api/external/submissions/submit`;
    return this.post<ApiResponse<any>>(url, request);
  }


  /**
   * Cancels the submission using the browser's navigator.sendBeacon api
   * Used to fire synchronous messages right before the browser tab closes
   */
  public cancelSubmissionUsingSendBeacon(requestSubmissionId: string, statusReasonCode: string) {
    const url: string = `${this._baseUri}/api/external/submissions/cancel`;
    const body: any = {
      RequestSubmissionId: requestSubmissionId,
      StatusReasonCode: statusReasonCode
    };
    const body2: BodyInit = JSON.stringify(body);
    const result: boolean = navigator.sendBeacon(url, body2);
  }

  public generatePresignedUrlForDocuments(filenames: string[], requestSubmissionId: string) {
    const url: string = `${this._baseUri}/api/external/submissions/generate/presigned-url`;
    console.log("filenames: ", filenames);
    const body = {
      filenames: filenames,
      requestSubmissionId: requestSubmissionId
    };
    return this.post<ApiResponse<GeneratePresignedUrlResponseItem[]>>(url, body).pipe(
      tap((response: ApiResponse<GeneratePresignedUrlResponseItem[]>) => console.log("api response: ", response))
    );

  }

  private isTpoContactAuthorized(tpoContact: TpoContact): boolean {

    if (tpoContact && tpoContact.allowedAccess && tpoContact.channelCode !== "None") {
      return true;
    }
    else {
      return false;
    }
  }


  /**
   * HTTP GET Request
   */
  private get<T>(url: string): Observable<T> {
    const accessToken = this._authService.getAccessToken();
    const result = accessToken.pipe(
      switchMap((value) => {
        return this._client.get<T>(url, {
          headers: new HttpHeaders({
            "Authorization": `Bearer ${value}`
          })
        });

      })
    );

    return result;
  }


  /**
   * HTTP POST Request
   */
  private post<T>(url: string, body: any): Observable<T> {
    const accessToken: Observable<string> = this._authService.getAccessToken();
    const response: Observable<T> = accessToken.pipe(
      switchMap((value: string) => {
        return this._client.post<T>(url, body, {
          headers: new HttpHeaders({
            "Authorization": `Bearer ${value}`
          }),

        });
      })
    );
    return response;
  }

  public getCompanyLicenses(companyNmlsId: string): Observable<NmlsCompanyLicense[]> {
    const url: string = `${this._baseUri}/api/nmls/company-licenses?companyNmlsId=${companyNmlsId}`;
    const response = this.get<NmlsCompanyLicense[]>(url);
    return response;
  }

  public getIndividualLicenses(individualNmlsId: string) {
    const url: string = `${this._baseUri}/api/nmls/individual-licenses?individualNmlsId=${individualNmlsId}`;
    const response = this.get<NmlsIndividualLicense[]>(url);
    return response;
  }
}
