// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  name: "staging",
  production: false,
  //bsaRequestToolConfigUri: "https://dh-public-web-assets.s3.amazonaws.com/test/online-forms/shared_ui/v1.0.0.4/bsa-request-tool/config.json",
  apiGatewayUri: "https://vh8qvz8kk2.execute-api.us-east-1.amazonaws.com/test",
  apiUri: "https://api.innovation.staging.deephavenmortgage.com",
  auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_Srkq5derE",
    userPoolWebClientId: "7brsq9nrvh2frbrqqsml3cj8t",
    domain: "staging-dh-innovation.auth.us-east-1.amazoncognito.com",
    scopes: [
      "phone", 
      "email", 
      "profile", 
      "openid",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: "https://innovation.staging.deephavenmortgage.com/oauth2/login",
    redirectSignOut: "https://innovation.staging.deephavenmortgage.com/dashboard"
  },
  zoomInfoScriptSrc: "",
  systemUnderMaintenance: false,
  adminEmailDomains: ["deephavenmortgage.com", "ramp51.com"],
  idleTimeoutMinutes: 5

};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
